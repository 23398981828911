.BlogContent,
.VacatureBody {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Metropolis-Medium";
  }

  p,
  span,
  li,
  a {
    font-family: "Metropolis-Regular";
    font-size: 16px;
    line-height: 1.5em;
  }

  a {
    text-decoration: none !important;
  }

  p.has-text-color {
    pointer-events: visible;
    cursor: pointer;
  }

  img {
    border-radius: 20px;
  }

  iframe {
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
  }

  .wp-block-columns.table {
    box-shadow: rgba(0, 0, 0, 0.098) 0px 3px 6px;
    border-radius: 20px;
    margin-top: 36px;
    overflow: hidden;
    padding-bottom: 16px;

    @media (max-width: 781px) {
      padding-bottom: 0;
    }

    .wp-block-column {
      position: relative;
      margin-left: -2px;

      @media (max-width: 781px) {
        padding: 24px;
        margin-left: 0;
        margin-bottom: -2px;

        &::after {
          content: "";
          width: calc(100% - 48px);
          left: 50%;
          height: 0.3px;
          bottom: 0;
          background-color: black;
          transform: translateX(-50%);
          position: absolute;
        }
      }

      p:empty {
        display: none;
      }

      p:not(:empty) {
        padding: 24px;
        margin: 0;

        @media (max-width: 781px) {
          padding: 0px 0px 8px 0px;
        }

        &:not(:has(strong)) {
          border-left: solid 0.3px black;

          @media (max-width: 781px) {
            border-left: solid 0px black;
          }
        }
      }

      .wp-block-heading:not(:empty) {
        padding: 24px;

        @media (max-width: 781px) {
          padding: 0px 0px 8px 0px;
        }

        &:not(:has(strong)) {
          border-left: solid 0.3px black;

          @media (max-width: 781px) {
            border-left: solid 0px black;
          }
        }
      }

      p:has(strong),
      .wp-block-heading:has(strong) {
        color: rgb(255, 255, 255);
        background-color: rgb(41, 70, 214);
        padding: 30px 0px 24px;
        display: flex;
        margin: 0;

        &:not(:empty) {
          margin-bottom: 16px;
        }

        @media (max-width: 781px) {
          background-color: transparent;
          color: rgb(41, 70, 214);
          padding: 0 0px 16px 0;
        }

        strong {
          margin: 0px;
          font-size: 18px;
          font-family: Metropolis-Bold;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  /* Additional default styles for the editor */

  pre {
    font-family: Consolas, Monaco, monospace;
  }

  td,
  th {
    font-family: inherit;
    font-size: inherit;
  }

  /* For emoji replacement images */
  img.emoji {
    display: inline !important;
    border: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .mceIEcenter {
    text-align: center;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .wp-caption {
    margin: 0;
    /* browser reset */
    max-width: 100%;
  }

  /* iOS does not obey max-width if width is set. */
  .ios .wp-caption {
    width: auto !important;
  }

  dl.wp-caption dt.wp-caption-dt img {
    display: inline-block;
    margin-bottom: -1ex;
  }

  div.mceTemp {
    // -ms-user-select: element;
  }

  dl.wp-caption,
  dl.wp-caption * {
    -webkit-user-drag: none;
  }

  .wp-caption-dd {
    font-size: 14px;
    padding-top: 0.5em;
    margin: 0;
    /* browser reset */
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }

  .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
  }

  /* Remove blue highlighting of selected images in WebKit */
  img[data-mce-selected]::selection {
    background-color: transparent;
  }

  /* Styles for the WordPress plugins */
  .mce-content-body img[data-mce-placeholder] {
    border-radius: 0;
    padding: 0;
  }

  .mce-content-body img[data-wp-more] {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 96%;
    height: 16px;
    display: block;
    margin: 15px auto 0;
    outline: 0;
    cursor: default;
  }

  .mce-content-body img[data-mce-placeholder][data-mce-selected] {
    outline: 1px dotted #888;
  }

  .mce-content-body img[data-wp-more="more"] {
    // background: transparent url('/images/more.png') repeat-y scroll center center;
  }

  .mce-content-body img[data-wp-more="nextpage"] {
    // background: transparent url('/images/pagebreak.png') repeat-y scroll center center;
  }

  /* Styles for formatting the boundaries of anchors and code elements */
  .mce-content-body a[data-mce-selected] {
    padding: 0 2px;
    margin: 0 -2px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #bfe6ff;
    background: #bfe6ff;
  }

  .mce-content-body .wp-caption-dt a[data-mce-selected] {
    outline: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: transparent;
  }

  .mce-content-body code {
    padding: 2px 4px;
    margin: 0;
    border-radius: 2px;
    color: #222;
    background: #f2f4f5;
  }

  .mce-content-body code[data-mce-selected] {
    background: #e9ebec;
  }

  /* Gallery, audio, video placeholders */
  .mce-content-body img.wp-media {
    border: 1px solid #aaa;
    background-color: #f2f2f2;
    background-repeat: no-repeat;
    background-position: center center;
    width: 99%;
    height: 250px;
    outline: 0;
    cursor: pointer;
  }

  .mce-content-body img.wp-media:hover {
    background-color: #ededed;
    border-color: #72777c;
  }

  .mce-content-body img.wp-media.wp-media-selected {
    background-color: #d8d8d8;
    border-color: #72777c;
  }

  .mce-content-body img.wp-media.wp-gallery {
    // background-image: url('/images/gallery.png');
  }

  /* Image resize handles */
  .mce-content-body div.mce-resizehandle {
    border-color: #72777c;
    width: 7px;
    height: 7px;
  }

  .mce-content-body img[data-mce-selected] {
    outline: 1px solid #72777c;
  }

  .mce-content-body img[data-mce-resize="false"] {
    outline: 0;
  }

  audio,
  video,
  embed {
    display: -moz-inline-stack;
    display: inline-block;
  }

  audio {
    visibility: hidden;
  }

  /* Fix for proprietary Mozilla display attribute, see #38757 */
  [_moz_abspos] {
    outline: none;
  }

  a[data-wplink-url-error],
  a[data-wplink-url-error]:hover,
  a[data-wplink-url-error]:focus {
    outline: 2px dotted #dc3232;
    position: relative;
  }

  a[data-wplink-url-error]:before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    outline: 2px dotted #fff;
    z-index: -1;
  }

  /**
 * WP Views
 */

  .wpview {
    width: 99.99%;
    /* All IE need hasLayout, incl. 11 (ugh, not again!!) */
    position: relative;
    clear: both;
    margin-bottom: 16px;
    border: 1px solid transparent;
  }

  .mce-shim {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .wpview[data-mce-selected="2"] .mce-shim {
    display: none;
  }

  .wpview .loading-placeholder {
    border: 1px dashed #ccc;
    padding: 10px;
  }

  .wpview[data-mce-selected] .loading-placeholder {
    border-color: transparent;
  }

  /* A little "loading" animation, not showing in IE < 10 */
  .wpview .wpview-loading {
    width: 60px;
    height: 5px;
    overflow: hidden;
    background-color: transparent;
    margin: 10px auto 0;
  }

  .wpview .wpview-loading ins {
    background-color: #333;
    margin: 0 0 0 -60px;
    width: 36px;
    height: 5px;
    display: block;
    -webkit-animation: wpview-loading 1.3s infinite 1s steps(36);
    animation: wpview-loading 1.3s infinite 1s steps(36);
  }

  @-webkit-keyframes wpview-loading {
    0% {
      margin-left: -60px;
    }

    100% {
      margin-left: 60px;
    }
  }

  @keyframes wpview-loading {
    0% {
      margin-left: -60px;
    }

    100% {
      margin-left: 60px;
    }
  }

  .wpview .wpview-content > iframe {
    max-width: 100%;
    background: transparent;
  }

  .wpview-error {
    border: 1px solid #ddd;
    padding: 1em 0;
    margin: 0;
    word-wrap: break-word;
  }

  .wpview[data-mce-selected] .wpview-error {
    border-color: transparent;
  }

  .wpview-error .dashicons,
  .loading-placeholder .dashicons {
    display: block;
    margin: 0 auto;
    width: 32px;
    height: 32px;
    font-size: 32px;
  }

  .wpview-error p {
    margin: 0;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }

  .wpview-type-gallery:after {
    content: "";
    display: table;
    clear: both;
  }

  .gallery img[data-mce-selected]:focus {
    outline: none;
  }

  .gallery a {
    cursor: default;
  }

  .gallery {
    margin: auto -6px;
    padding: 6px 0;
    line-height: 1;
    overflow-x: hidden;
  }

  .ie7 .gallery,
  .ie8 .gallery {
    margin: auto;
  }

  .gallery .gallery-item {
    float: left;
    margin: 0;
    text-align: center;
    padding: 6px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .ie7 .gallery .gallery-item,
  .ie8 .gallery .gallery-item {
    padding: 6px 0;
  }

  .gallery .gallery-caption,
  .gallery .gallery-icon {
    margin: 0;
  }

  .gallery .gallery-caption {
    font-size: 13px;
    margin: 4px 0;
  }

  .gallery-columns-1 .gallery-item {
    width: 100%;
  }

  .gallery-columns-2 .gallery-item {
    width: 50%;
  }

  .gallery-columns-3 .gallery-item {
    width: 33.333%;
  }

  .ie8 .gallery-columns-3 .gallery-item,
  .ie7 .gallery-columns-3 .gallery-item {
    width: 33%;
  }

  .gallery-columns-4 .gallery-item {
    width: 25%;
  }

  .gallery-columns-5 .gallery-item {
    width: 20%;
  }

  .gallery-columns-6 .gallery-item {
    width: 16.665%;
  }

  .gallery-columns-7 .gallery-item {
    width: 14.285%;
  }

  .gallery-columns-8 .gallery-item {
    width: 12.5%;
  }

  .gallery-columns-9 .gallery-item {
    width: 11.111%;
  }

  .gallery img {
    max-width: 100%;
    height: auto;
    border: none;
    padding: 0;
  }

  img.wp-oembed {
    border: 1px dashed #888;
    // background: #f7f5f2 url('/images/embedded.png') no-repeat scroll center center;
    width: 300px;
    height: 250px;
    outline: 0;
  }

  /* rtl */
  .rtl .gallery .gallery-item {
    float: right;
  }

  @media print,
    (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi) {
    .mce-content-body img.mce-wp-more {
      // background-image: url('/images/more-2x.png');
      background-size: 1900px 20px;
    }

    .mce-content-body img.mce-wp-nextpage {
      // background-image: url('/images/pagebreak-2x.png');
      background-size: 1900px 20px;
    }
  }
}
