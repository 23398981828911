.streched-link {
  position: absolute;
  inset: 0;
}

.link {
  text-decoration: underline;
  color: #2946d6;
}

svg:not(:root) {
  overflow: visible;
}

.slide-comp {

  table,
  td {
    border: none;
  }

  td {
    position: relative;
    padding: 10px;

    // .popup-gallery::before {
    //   content: "";
    //   background-color: rgb(98, 103, 132);
    //   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01MS44NDcgNTUuOTY2bC03Ljk1NS03Ljk1NUw4MC41NCAxMS4zNjRINjguMTgyVjBIMTAwdjMxLjY3Nkg4OC42MzZWMTkuMzE4bC0zNi43OSAzNi42NDh6bTMzLjM4IDQzLjc1SDUuNjgyQzIuNTU3IDk5LjcxNiAwIDk3LjE1OSAwIDk0LjAzNFYxNC40OWMwLTMuMTI1IDIuNTU3LTUuNjgyIDUuNjgyLTUuNjgyaDQ1LjQ1NFYyMC4xN0gxMS4zNjR2NjguMTgxaDY4LjE4MVY0OC41OEg5MC45MXY0NS40NTVjMCAzLjEyNS0yLjU1NyA1LjY4Mi01LjY4MiA1LjY4MnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
    //   z-index: 1;
    //   height: 24px;
    //   width: 24px;
    //   position: absolute;
    //   background-repeat: no-repeat;
    //   background-size: 59%;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   right: 0;
    //   background-position: center;
    // }
  }

  // .popup-gallery {
  //   position: relative;
  //   &::before {
  //     content: "";
  //     background-color: rgb(98, 103, 132);
  //     background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01MS44NDcgNTUuOTY2bC03Ljk1NS03Ljk1NUw4MC41NCAxMS4zNjRINjguMTgyVjBIMTAwdjMxLjY3Nkg4OC42MzZWMTkuMzE4bC0zNi43OSAzNi42NDh6bTMzLjM4IDQzLjc1SDUuNjgyQzIuNTU3IDk5LjcxNiAwIDk3LjE1OSAwIDk0LjAzNFYxNC40OWMwLTMuMTI1IDIuNTU3LTUuNjgyIDUuNjgyLTUuNjgyaDQ1LjQ1NFYyMC4xN0gxMS4zNjR2NjguMTgxaDY4LjE4MVY0OC41OEg5MC45MXY0NS40NTVjMCAzLjEyNS0yLjU1NyA1LjY4Mi01LjY4MiA1LjY4MnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
  //     z-index: 1;
  //     height: 24px;
  //     width: 24px;
  //     position: absolute;
  //     background-repeat: no-repeat;
  //     background-size: 59%;
  //     top: -40%;
  //     transform: translateY(-50%);
  //     right: 0;
  //     background-position: center;
  //   }
  // }
}

.loading svg path,
.loading svg rect {
  fill: #2946d6;
}