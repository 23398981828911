@font-face {
  font-family: "Metropolis-ExtraBold";
  src: local("Metropolis-ExtraBold"),
    url("../../components/atoms/fonts/Metropolis-ExtraBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Metropolis-Bold";
  src: local("Metropolis-Bold"),
    url("../../components/atoms/fonts/Metropolis-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Metropolis-Regular";
  src: local("Metropolis-Regular"),
    url("../../components/atoms/fonts/Metropolis-Regular.otf")
      format("opentype");
}
@font-face {
  font-family: "Metropolis-RegularItalic";
  src: local("Metropolis-RegularItalic"),
    url("../../components/atoms/fonts/Metropolis-RegularItalic.otf")
      format("opentype");
}
@font-face {
  font-family: "Metropolis-SemiBold";
  src: local("Metropolis-SemiBold"),
    url("../../components/atoms/fonts/Metropolis-SemiBold.otf")
      format("opentype");
}
@font-face {
  font-family: "Metropolis-Medium";
  src: local("Metropolis-Medium"),
    url("../../components/atoms/fonts/Metropolis-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Metropolis-Light";
  src: local("Metropolis-Light"),
    url("../../components/atoms/fonts/Metropolis-Light.otf") format("opentype");
}
