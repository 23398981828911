@use "var";

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  margin: 0;
  color: $black;
  // background-color: #f7f7f7;
}

html,
body {
  overflow-x: clip;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  position: relative;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 40px;
  font-family: "Metropolis-Bold";
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;
}

h2 {
  font-size: 35px;
  font-family: "Metropolis-Bold";
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;
}

h3 {
  font-size: 33px;
  font-family: "Metropolis-Bold";
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;

  @media(max-width: 1440px) {
    font-size: 26px;
  }

  @media(max-width: 1281px) {
    font-size: 22px;
  }

  @media(max-width: 600px) {
    font-size: 24px;
  }
}

h4 {
  font-size: 18px;
  font-family: "Metropolis-Bold";
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-family: "Metropolis-Bold";
  font-weight: bold;
  letter-spacing: 0;
  line-height: auto;
}

body {
  font-size: 16px;
  font-family: "Metropolis-Regular";
  font-weight: normal;
  letter-spacing: 0;
  line-height: 28px;
}