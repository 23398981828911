.single-block {
            &.single-block {
                --wp--preset--color--black: #000000;
                --wp--preset--color--cyan-bluish-gray: #abb8c3;
                --wp--preset--color--white: #ffffff;
                --wp--preset--color--pale-pink: #f78da7;
                --wp--preset--color--vivid-red: #cf2e2e;
                --wp--preset--color--luminous-vivid-orange: #ff6900;
                --wp--preset--color--luminous-vivid-amber: #fcb900;
                --wp--preset--color--light-green-cyan: #7bdcb5;
                --wp--preset--color--vivid-green-cyan: #00d084;
                --wp--preset--color--pale-cyan-blue: #8ed1fc;
                --wp--preset--color--vivid-cyan-blue: #0693e3;
                --wp--preset--color--vivid-purple: #9b51e0;
                --wp--preset--color--foreground: #000000;
                --wp--preset--color--background: #ffffff;
                --wp--preset--color--primary: #1a4548;
                --wp--preset--color--secondary: #ffe2c7;
                --wp--preset--color--tertiary: #F6F6F6;
                --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
                --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
                --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
                --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
                --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
                --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
                --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
                --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
                --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
                --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
                --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
                --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
                --wp--preset--gradient--vertical-secondary-to-tertiary: linear-gradient(to bottom,var(--wp--preset--color--secondary) 0%,var(--wp--preset--color--tertiary) 100%);
                --wp--preset--gradient--vertical-secondary-to-background: linear-gradient(to bottom,var(--wp--preset--color--secondary) 0%,var(--wp--preset--color--background) 100%);
                --wp--preset--gradient--vertical-tertiary-to-background: linear-gradient(to bottom,var(--wp--preset--color--tertiary) 0%,var(--wp--preset--color--background) 100%);
                --wp--preset--gradient--diagonal-primary-to-foreground: linear-gradient(to bottom right,var(--wp--preset--color--primary) 0%,var(--wp--preset--color--foreground) 100%);
                --wp--preset--gradient--diagonal-secondary-to-background: linear-gradient(to bottom right,var(--wp--preset--color--secondary) 50%,var(--wp--preset--color--background) 50%);
                --wp--preset--gradient--diagonal-background-to-secondary: linear-gradient(to bottom right,var(--wp--preset--color--background) 50%,var(--wp--preset--color--secondary) 50%);
                --wp--preset--gradient--diagonal-tertiary-to-background: linear-gradient(to bottom right,var(--wp--preset--color--tertiary) 50%,var(--wp--preset--color--background) 50%);
                --wp--preset--gradient--diagonal-background-to-tertiary: linear-gradient(to bottom right,var(--wp--preset--color--background) 50%,var(--wp--preset--color--tertiary) 50%);
                --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
                --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
                --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
                --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
                --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
                --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
                --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
                --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
                --wp--preset--duotone--foreground-and-background: url('#wp-duotone-foreground-and-background');
                --wp--preset--duotone--foreground-and-secondary: url('#wp-duotone-foreground-and-secondary');
                --wp--preset--duotone--foreground-and-tertiary: url('#wp-duotone-foreground-and-tertiary');
                --wp--preset--duotone--primary-and-background: url('#wp-duotone-primary-and-background');
                --wp--preset--duotone--primary-and-secondary: url('#wp-duotone-primary-and-secondary');
                --wp--preset--duotone--primary-and-tertiary: url('#wp-duotone-primary-and-tertiary');
                --wp--preset--font-size--small: 1rem;
                --wp--preset--font-size--medium: 1.125rem;
                --wp--preset--font-size--large: 1.75rem;
                --wp--preset--font-size--x-large: clamp(1.75rem, 3vw, 2.25rem);
                --wp--preset--font-family--system-font: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
                --wp--preset--font-family--source-serif-pro: "Source Serif Pro", serif;
                --wp--preset--spacing--20: 0.44rem;
                --wp--preset--spacing--30: 0.67rem;
                --wp--preset--spacing--40: 1rem;
                --wp--preset--spacing--50: 1.5rem;
                --wp--preset--spacing--60: 2.25rem;
                --wp--preset--spacing--70: 3.38rem;
                --wp--preset--spacing--80: 5.06rem;
                --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
                --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
                --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
                --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
                --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
                --wp--custom--spacing--small: max(1.25rem, 5vw);
                --wp--custom--spacing--medium: clamp(2rem, 8vw, calc(4 * var(--wp--style--block-gap)));
                --wp--custom--spacing--large: clamp(4rem, 10vw, 8rem);
                --wp--custom--spacing--outer: var(--wp--custom--spacing--small, 1.25rem);
                --wp--custom--typography--font-size--huge: clamp(2.25rem, 4vw, 2.75rem);
                --wp--custom--typography--font-size--gigantic: clamp(2.75rem, 6vw, 3.25rem);
                --wp--custom--typography--font-size--colossal: clamp(3.25rem, 8vw, 6.25rem);
                --wp--custom--typography--line-height--tiny: 1.15;
                --wp--custom--typography--line-height--small: 1.2;
                --wp--custom--typography--line-height--medium: 1.4;
                --wp--custom--typography--line-height--normal: 1.6;
            }

            &.single-block {
                margin: 0;
                --wp--style--global--content-size: 650px;
                --wp--style--global--wide-size: 1000px;
            }

            .wp-site-blocks > .alignleft {
                float: left;
                margin-right: 2em;
            }

            .wp-site-blocks > .alignright {
                float: right;
                margin-left: 2em;
            }

            .wp-site-blocks > .aligncenter {
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }

            .wp-site-blocks > * {
                margin-block-start: 0; margin-block-end: 0; }

            .wp-site-blocks > * + * {
                margin-block-start: 1.5rem; }

            &.single-block {
                --wp--style--block-gap: 1.5rem;
            }

            &.single-block .is-layout-flow > * {
                margin-block-start: 0;margin-block-end: 0;}

            &.single-block .is-layout-flow > * + * {
                margin-block-start: 1.5rem;margin-block-end: 0;}

            &.single-block .is-layout-constrained > * {
                margin-block-start: 0;margin-block-end: 0;}

            &.single-block .is-layout-constrained > * + * {
                margin-block-start: 1.5rem;margin-block-end: 0;}

            &.single-block .is-layout-flex {
                gap: 1.5rem;
            }

            &.single-block .is-layout-flow > .alignleft {
                float: left;
                margin-inline-start: 0;margin-inline-end: 2em;}

            &.single-block .is-layout-flow > .alignright {
                float: right;
                margin-inline-start: 2em;margin-inline-end: 0;}

            &.single-block .is-layout-flow > .aligncenter {
                margin-left: auto !important;
                margin-right: auto !important;
            }

            &.single-block .is-layout-constrained > .alignleft {
                float: left;
                margin-inline-start: 0;margin-inline-end: 2em;}

            &.single-block .is-layout-constrained > .alignright {
                float: right;
                margin-inline-start: 2em;margin-inline-end: 0;}

            &.single-block .is-layout-constrained > .aligncenter {
                margin-left: auto !important;
                margin-right: auto !important;
            }

            &.single-block .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
                max-width: var(--wp--style--global--content-size);
                margin-left: auto !important;
                margin-right: auto !important;
            }

            &.single-block .is-layout-constrained > .alignwide {
                max-width: var(--wp--style--global--wide-size);
            }

            &.single-block .is-layout-flex {
                display: flex;
            }

            &.single-block .is-layout-flex {
                flex-wrap: wrap;
                align-items: center;
            }

            &.single-block .is-layout-flex > * {
                margin: 0;
            }

            &.single-block {
                background-color: var(--wp--preset--color--background);
                color: var(--wp--preset--color--foreground);
                font-family: var(--wp--preset--font-family--system-font);
                font-size: var(--wp--preset--font-size--medium);
                line-height: var(--wp--custom--typography--line-height--normal);
                padding-top: 0px;
                padding-right: 0px;
                padding-bottom: 0px;
                padding-left: 0px;
            }

            a:where(:not(.wp-element-button)) {
                color: var(--wp--preset--color--foreground);
                text-decoration: underline;
            }

            h1 {
                font-family: var(--wp--preset--font-family--source-serif-pro);
                font-size: var(--wp--custom--typography--font-size--colossal);
                font-weight: 300;
                line-height: var(--wp--custom--typography--line-height--tiny);
            }

            h2 {
                font-family: var(--wp--preset--font-family--source-serif-pro);
                font-size: var(--wp--custom--typography--font-size--gigantic);
                font-weight: 300;
                line-height: var(--wp--custom--typography--line-height--small);
            }

            h3 {
                font-family: var(--wp--preset--font-family--source-serif-pro);
                font-size: var(--wp--custom--typography--font-size--huge);
                font-weight: 300;
                line-height: var(--wp--custom--typography--line-height--tiny);
            }

            h4 {
                font-family: var(--wp--preset--font-family--source-serif-pro);
                font-size: var(--wp--preset--font-size--x-large);
                font-weight: 300;
                line-height: var(--wp--custom--typography--line-height--tiny);
            }

            h5 {
                font-family: var(--wp--preset--font-family--system-font);
                font-size: var(--wp--preset--font-size--medium);
                font-weight: 700;
                line-height: var(--wp--custom--typography--line-height--normal);
                text-transform: uppercase;
            }

            h6 {
                font-family: var(--wp--preset--font-family--system-font);
                font-size: var(--wp--preset--font-size--medium);
                font-weight: 400;
                line-height: var(--wp--custom--typography--line-height--normal);
                text-transform: uppercase;
            }

            .wp-element-button, .wp-block-button__link {
                background-color: #32373c;
                border-width: 0;
                color: #fff;
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
                padding: calc(0.667em + 2px) calc(1.333em + 2px);
                text-decoration: none;
            }

            .has-black-color {
                color: var(--wp--preset--color--black) !important;
            }

            .has-cyan-bluish-gray-color {
                color: var(--wp--preset--color--cyan-bluish-gray) !important;
            }

            .has-white-color {
                color: var(--wp--preset--color--white) !important;
            }

            .has-pale-pink-color {
                color: var(--wp--preset--color--pale-pink) !important;
            }

            .has-vivid-red-color {
                color: var(--wp--preset--color--vivid-red) !important;
            }

            .has-luminous-vivid-orange-color {
                color: var(--wp--preset--color--luminous-vivid-orange) !important;
            }

            .has-luminous-vivid-amber-color {
                color: var(--wp--preset--color--luminous-vivid-amber) !important;
            }

            .has-light-green-cyan-color {
                color: var(--wp--preset--color--light-green-cyan) !important;
            }

            .has-vivid-green-cyan-color {
                color: var(--wp--preset--color--vivid-green-cyan) !important;
            }

            .has-pale-cyan-blue-color {
                color: var(--wp--preset--color--pale-cyan-blue) !important;
            }

            .has-vivid-cyan-blue-color {
                color: var(--wp--preset--color--vivid-cyan-blue) !important;
            }

            .has-vivid-purple-color {
                color: var(--wp--preset--color--vivid-purple) !important;
            }

            .has-foreground-color {
                color: var(--wp--preset--color--foreground) !important;
            }

            .has-background-color {
                color: var(--wp--preset--color--background) !important;
            }

            .has-primary-color {
                color: var(--wp--preset--color--primary) !important;
            }

            .has-secondary-color {
                color: var(--wp--preset--color--secondary) !important;
            }

            .has-tertiary-color {
                color: var(--wp--preset--color--tertiary) !important;
            }

            .has-black-background-color {
                background-color: var(--wp--preset--color--black) !important;
            }

            .has-cyan-bluish-gray-background-color {
                background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
            }

            .has-white-background-color {
                background-color: var(--wp--preset--color--white) !important;
            }

            .has-pale-pink-background-color {
                background-color: var(--wp--preset--color--pale-pink) !important;
            }

            .has-vivid-red-background-color {
                background-color: var(--wp--preset--color--vivid-red) !important;
            }

            .has-luminous-vivid-orange-background-color {
                background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
            }

            .has-luminous-vivid-amber-background-color {
                background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
            }

            .has-light-green-cyan-background-color {
                background-color: var(--wp--preset--color--light-green-cyan) !important;
            }

            .has-vivid-green-cyan-background-color {
                background-color: var(--wp--preset--color--vivid-green-cyan) !important;
            }

            .has-pale-cyan-blue-background-color {
                background-color: var(--wp--preset--color--pale-cyan-blue) !important;
            }

            .has-vivid-cyan-blue-background-color {
                background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
            }

            .has-vivid-purple-background-color {
                background-color: var(--wp--preset--color--vivid-purple) !important;
            }

            .has-foreground-background-color {
                background-color: var(--wp--preset--color--foreground) !important;
            }

            .has-background-background-color {
                background-color: var(--wp--preset--color--background) !important;
            }

            .has-primary-background-color {
                background-color: var(--wp--preset--color--primary) !important;
            }

            .has-secondary-background-color {
                background-color: var(--wp--preset--color--secondary) !important;
            }

            .has-tertiary-background-color {
                background-color: var(--wp--preset--color--tertiary) !important;
            }

            .has-black-border-color {
                border-color: var(--wp--preset--color--black) !important;
            }

            .has-cyan-bluish-gray-border-color {
                border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
            }

            .has-white-border-color {
                border-color: var(--wp--preset--color--white) !important;
            }

            .has-pale-pink-border-color {
                border-color: var(--wp--preset--color--pale-pink) !important;
            }

            .has-vivid-red-border-color {
                border-color: var(--wp--preset--color--vivid-red) !important;
            }

            .has-luminous-vivid-orange-border-color {
                border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
            }

            .has-luminous-vivid-amber-border-color {
                border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
            }

            .has-light-green-cyan-border-color {
                border-color: var(--wp--preset--color--light-green-cyan) !important;
            }

            .has-vivid-green-cyan-border-color {
                border-color: var(--wp--preset--color--vivid-green-cyan) !important;
            }

            .has-pale-cyan-blue-border-color {
                border-color: var(--wp--preset--color--pale-cyan-blue) !important;
            }

            .has-vivid-cyan-blue-border-color {
                border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
            }

            .has-vivid-purple-border-color {
                border-color: var(--wp--preset--color--vivid-purple) !important;
            }

            .has-foreground-border-color {
                border-color: var(--wp--preset--color--foreground) !important;
            }

            .has-background-border-color {
                border-color: var(--wp--preset--color--background) !important;
            }

            .has-primary-border-color {
                border-color: var(--wp--preset--color--primary) !important;
            }

            .has-secondary-border-color {
                border-color: var(--wp--preset--color--secondary) !important;
            }

            .has-tertiary-border-color {
                border-color: var(--wp--preset--color--tertiary) !important;
            }

            .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
                background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
            }

            .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
                background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
            }

            .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
                background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
            }

            .has-luminous-vivid-orange-to-vivid-red-gradient-background {
                background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
            }

            .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
                background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
            }

            .has-cool-to-warm-spectrum-gradient-background {
                background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
            }

            .has-blush-light-purple-gradient-background {
                background: var(--wp--preset--gradient--blush-light-purple) !important;
            }

            .has-blush-bordeaux-gradient-background {
                background: var(--wp--preset--gradient--blush-bordeaux) !important;
            }

            .has-luminous-dusk-gradient-background {
                background: var(--wp--preset--gradient--luminous-dusk) !important;
            }

            .has-pale-ocean-gradient-background {
                background: var(--wp--preset--gradient--pale-ocean) !important;
            }

            .has-electric-grass-gradient-background {
                background: var(--wp--preset--gradient--electric-grass) !important;
            }

            .has-midnight-gradient-background {
                background: var(--wp--preset--gradient--midnight) !important;
            }

            .has-vertical-secondary-to-tertiary-gradient-background {
                background: var(--wp--preset--gradient--vertical-secondary-to-tertiary) !important;
            }

            .has-vertical-secondary-to-background-gradient-background {
                background: var(--wp--preset--gradient--vertical-secondary-to-background) !important;
            }

            .has-vertical-tertiary-to-background-gradient-background {
                background: var(--wp--preset--gradient--vertical-tertiary-to-background) !important;
            }

            .has-diagonal-primary-to-foreground-gradient-background {
                background: var(--wp--preset--gradient--diagonal-primary-to-foreground) !important;
            }

            .has-diagonal-secondary-to-background-gradient-background {
                background: var(--wp--preset--gradient--diagonal-secondary-to-background) !important;
            }

            .has-diagonal-background-to-secondary-gradient-background {
                background: var(--wp--preset--gradient--diagonal-background-to-secondary) !important;
            }

            .has-diagonal-tertiary-to-background-gradient-background {
                background: var(--wp--preset--gradient--diagonal-tertiary-to-background) !important;
            }

            .has-diagonal-background-to-tertiary-gradient-background {
                background: var(--wp--preset--gradient--diagonal-background-to-tertiary) !important;
            }

            .has-small-font-size {
                font-size: var(--wp--preset--font-size--small) !important;
            }

            .has-medium-font-size {
                font-size: var(--wp--preset--font-size--medium) !important;
            }

            .has-large-font-size {
                font-size: var(--wp--preset--font-size--large) !important;
            }

            .has-x-large-font-size {
                font-size: var(--wp--preset--font-size--x-large) !important;
            }

            .has-system-font-font-family {
                font-family: var(--wp--preset--font-family--system-font) !important;
            }

            .has-source-serif-pro-font-family {
                font-family: var(--wp--preset--font-family--source-serif-pro) !important;
            }
        }