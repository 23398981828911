.slide-comp {
  a {
    position: relative;
    color: #2946d6;

    &:has(img) {
      display: inline-block;

      &::before {
        display: block;
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: none;
      bottom: 0;
      top: 0;
      right: -10px;
      margin: auto;
      border-radius: 3px;
      width: 20px;
      height: 20px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzAiIGhlaWdodD0iMzAiCnZpZXdCb3g9IjAsMCwyNTYsMjU2IgpzdHlsZT0iZmlsbDojMDAwMDAwOyI+CjxnIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxnIHRyYW5zZm9ybT0ic2NhbGUoOC41MzMzMyw4LjUzMzMzKSI+PHBhdGggZD0iTTI1Ljk4MDQ3LDIuOTkwMjNjLTAuMDM3MjYsMC4wMDExOCAtMC4wNzQ0MywwLjAwNDQ0IC0wLjExMTMzLDAuMDA5NzdoLTUuODY5MTRjLTAuMzYwNjQsLTAuMDA1MSAtMC42OTYwOCwwLjE4NDM4IC0wLjg3Nzg5LDAuNDk1ODdjLTAuMTgxODEsMC4zMTE1IC0wLjE4MTgxLDAuNjk2NzYgMCwxLjAwODI1YzAuMTgxODEsMC4zMTE1IDAuNTE3MjUsMC41MDA5NyAwLjg3Nzg5LDAuNDk1ODdoMy41ODU5NGwtMTAuMjkyOTcsMTAuMjkyOTdjLTAuMjYxMjQsMC4yNTA4MiAtMC4zNjY0OCwwLjYyMzI3IC0wLjI3NTEyLDAuOTczNzFjMC4wOTEzNiwwLjM1MDQ0IDAuMzY1MDMsMC42MjQxMSAwLjcxNTQ3LDAuNzE1NDdjMC4zNTA0NCwwLjA5MTM2IDAuNzIyODksLTAuMDEzODggMC45NzM3MSwtMC4yNzUxMmwxMC4yOTI5NywtMTAuMjkyOTd2My41ODU5NGMtMC4wMDUxLDAuMzYwNjQgMC4xODQzOCwwLjY5NjA4IDAuNDk1ODcsMC44Nzc4OWMwLjMxMTUsMC4xODE4MSAwLjY5Njc2LDAuMTgxODEgMS4wMDgyNSwwYzAuMzExNSwtMC4xODE4MSAwLjUwMDk3LC0wLjUxNzI1IDAuNDk1ODcsLTAuODc3ODl2LTUuODczMDVjMC4wNDAzMSwtMC4yOTE0MSAtMC4wNDk3MywtMC41ODU3OSAtMC4yNDYxNSwtMC44MDQ3OWMtMC4xOTY0MywtMC4yMTkgLTAuNDc5MzEsLTAuMzQwNDIgLTAuNzczMzgsLTAuMzMxOTJ6TTYsN2MtMS4wOTMwNiwwIC0yLDAuOTA2OTQgLTIsMnYxNWMwLDEuMDkzMDYgMC45MDY5NCwyIDIsMmgxNWMxLjA5MzA2LDAgMiwtMC45MDY5NCAyLC0ydi0xMHYtMi41NzgxMmwtMiwydjIuNTc4MTN2OGgtMTV2LTE1aDhoMmgwLjU3ODEzbDIsLTJoLTIuNTc4MTJoLTJ6Ij48L3BhdGg+PC9nPjwvZz4KPC9zdmc+') 50% 50% no-repeat;
      background-size: 80%;
      background-color: #626784;
    }
  }

  // h1:first-child {
  //   font-size: 35px;
  //   font-family: "Metropolis-Bold";
  //   font-weight: bold;
  //   letter-spacing: 0;
  //   line-height: 1;

  //   padding-bottom: 30px;
  //   border-bottom: #2946d6 solid 4px;
  //   margin-left: -24px;
  // }
}

.swiper-slide {
  video {
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  height: auto;
  display: flex;
  flex-direction: column;
}
