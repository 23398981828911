.swipperWrapper {
  .swiper-pagination-bullets.swiper-pagination-bullets.swiper-pagination-bullets {
    margin-top: 16px;
    transform: translateX(0) scale(1.2);
    margin-inline: auto;
    @media (max-width: 1020px) {
      transform: translateX(0) scale(1.2);
      left: 0;
    }
  }

  .swiperPrev {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 0;
    height: 50px;
    width: 50px;
    z-index: 10;

    > svg {
      height: 50px;
      width: 50px;
    }
  }
  .swiperNext {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 0;
    height: 50px;
    width: 50px;
    z-index: 10;

    > svg {
      height: 50px;
      width: 50px;
    }
  }

  .swiper-button-disabled {
    opacity: 0.1;
  }

  .white {
    .swiper-pagination-bullet-active {
      background-color: white;
    }
  }

  .scrollbar {
    background: #e4e6f6;
    border-radius: 16px;
    margin-top: 16px;

    .swiper-scrollbar-drag {
      height: 10px;
      background: rgb(48, 69, 206);
      border-radius: 15px;
    }
  }
}
